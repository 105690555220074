// React App Index

// DYNAMIC APPS
// Index of {app name}: {js hook}
// JS Hooks have to be '.js-' prefixed ids

const noop = () => {}
if (document.querySelector('#js-onboarding-root')) {
    import('./onboarding').then(noop)
}
if (document.querySelector('#js-search-root')) {
    import('./search').then(noop)
}
if (document.querySelector('#js-isin-checker-root')) {
    import('./isinchecker').then(noop)
}
if (document.querySelector('#js-coverage-checker-root')) {
    import('./coverage').then(noop)
}
if (document.querySelector('#js-analyses-root')) {
    import('./analyses').then(noop)
}
if (document.querySelector('#js-bookmarks-root')) {
    import('./bookmarks').then(noop)
}
if (document.querySelector('#js-sustainability-profile-root')) {
    import('./sustainability').then(noop)
}
if (document.querySelector('#js-settings-root')) {
    import('./settings').then(noop)
}
if (document.querySelector('#js-fund-report-root')) {
    import('./fund').then(noop)
}
if (document.querySelector('#js-fund-pdf-root')) {
    import('./pdf').then(noop)
}
if (document.querySelector('#js-fundgroup-root')) {
    import('./fundgroup').then(noop)
}
if (document.querySelector('#js-subscriptionplans-root')) {
    import('./subscriptionplans').then(noop)
}

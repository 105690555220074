import Cookies from 'js-cookie'

const SELECTORS = {
    notice: '#js-cookienotice',
    acceptBtn: '.js-cookienotice-accept',
    rejectBtn: '.js-cookienotice-reject',
    revokeLink: '.js-revoke-cookie-consent'
}

class CookieNotice {
    constructor(fn, waitAccept) {
        // Properties
        this.cookieTimeoutDays = 365
        this.bots = /bot|crawler|spider|crawling/i
        this.cookieName = 'hasCookieConsent'
        this.trackingCookieNames = [
            '__utma',
            '__utmb',
            '__utmc',
            '__utmt',
            '__utmv',
            '__utmz',
            '_ga',
            '_gat',
            '_gid'
        ]
        this.callback = fn
        this.waitAccept = waitAccept || true

        // Elements
        this.notice = document.querySelector(SELECTORS.notice)
        Array.from(document.querySelectorAll(SELECTORS.revokeLink), (link) =>
            link.addEventListener('click', () => this.revokeConsent())
        )

        // Kick it off
        this.init()
    }

    init() {
        // Do nothing if DNT, Bot, or previously rejected cookies
        if (
            !CookieNotice.allowsTracking() ||
            this.isBot() ||
            this.hasConsent() === false ||
            !this.notice
        ) {
            return false
        }

        // User has already consented to cookies, enable tracking
        if (this.hasConsent() === true) {
            this.callback()
            return true
        }

        // show notice
        this.showNotice()

        // pre-emptively accept cookies if configured
        if (!this.waitAccept) {
            this.acceptCookies()
        }
    }

    showNotice() {
        // Display the notice and bind the accept/reject actions
        const acceptBtn = this.notice.querySelector(SELECTORS.acceptBtn)
        const rejectBtn = this.notice.querySelector(SELECTORS.rejectBtn)

        this.notice.removeAttribute('hidden')

        if (acceptBtn) {
            acceptBtn.addEventListener('click', () => this.acceptCookies())
        }
        if (rejectBtn) {
            rejectBtn.addEventListener('click', () => this.rejectCookies())
        }
    }

    hideNotice() {
        this.notice.setAttribute('hidden', true)
    }

    acceptCookies() {
        Cookies.set(this.cookieName, true, { expires: this.cookieTimeoutDays })
        this.hideNotice()
        this.callback()
    }

    rejectCookies() {
        Cookies.set(this.cookieName, false, { expires: this.cookieTimeoutDays })
        this.hideNotice()
        this.deleteTrackingCookies()
    }

    hasConsent() {
        const cookieValue = Cookies.get(this.cookieName)
        switch (cookieValue) {
            case 'true':
                return true
            case 'false':
                return false
            default:
                return undefined
        }
    }

    revokeConsent() {
        Cookies.remove(this.cookieName)
        this.deleteTrackingCookies()
        window.location.reload()
    }

    isBot() {
        return this.bots.test(navigator.userAgent)
    }

    deleteTrackingCookies() {
        this.trackingCookieNames.map((cookieName) => Cookies.remove(cookieName))
    }

    static allowsTracking() {
        const dnt =
            navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack
        return dnt !== null && dnt !== undefined
            ? dnt && dnt !== 'yes' && dnt !== 1 && dnt !== '1'
            : true
    }
}

// ================================
// DEFINE TRACKING HERE
// ================================
function gtag() {
    window.dataLayer.push(arguments)
}

new CookieNotice(function () {
    window.dataLayer = window.dataLayer || []
    window.gtag = gtag
    ;(function (d, s, i) {
        gtag('js', new Date())
        gtag('config', 'G-QNK46Z3Y9H')
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s)
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i
        f.parentNode.insertBefore(j, f)
    })(document, 'script', 'G-QNK46Z3Y9H')
})

// Navigation
import { createFocusTrap, onClickOutsideOf } from '../utils'

const SELECTORS = {
    nav: '.js-nav',
    toggleButton: '.js-nav-toggle',
    submenuToggleButton: '.js-nav-submenu-toggle'
}

const CLASSES = {
    bodyClass: 'nav-open',
    isOpen: 'is-open'
}

const MOBILE_MQ = window.matchMedia('(max-width: 991px)')

class Navigation {
    constructor(el) {
        this.nav = el
        this.toggleBtn = this.nav.querySelector(SELECTORS.toggleButton)
        this.submenuToggleBtns = this.nav.querySelectorAll(
            SELECTORS.submenuToggleButton
        )

        this.isOpen = false
    }

    init() {
        this.focusTrap = createFocusTrap(this.nav, {
            toggleElement: this.toggleBtn,
            onEscape: () => this.toggleMenu(false)
        })
        this.bindEvents()
    }

    bindEvents() {
        this.outsideClickHandler = null
        this.toggleBtn.addEventListener('click', this.toggleMenu)

        if (this.submenuToggleBtns.length) {
            Array.from(this.submenuToggleBtns).forEach((btn) => {
                btn.addEventListener('click', (e) => {
                    e.preventDefault()
                    this.toggleSubmenu(btn)
                })
            })
        }
    }

    toggleMenu = (force) => {
        this.isOpen = typeof force === 'boolean' ? force : !this.isOpen

        this.toggleBtn.setAttribute('aria-expanded', String(this.isOpen))
        document.body.classList.toggle(CLASSES.bodyClass, this.isOpen)

        if (this.isOpen) {
            this.focusTrap.activate()
        } else {
            this.focusTrap.deactivate()
        }
    }

    toggleSubmenu = (btn) => {
        const submenuId = btn.getAttribute('aria-controls')
        const isOpen = btn.getAttribute('aria-expanded') === 'true'

        const submenu = document.getElementById(submenuId)
        const menuItem = btn.parentNode

        if (!submenu) {
            return false
        }

        if (!isOpen && !MOBILE_MQ.matches) {
            window.setTimeout(() => {
                this.outsideClickHandler = onClickOutsideOf(submenu, () =>
                    this.closeAllSubmenus()
                )
            }, 200)
        }

        this.closeAllSubmenus()
        menuItem.classList.toggle(CLASSES.isOpen, !isOpen)
        btn.setAttribute('aria-expanded', String(!isOpen))
    }

    closeAllSubmenus() {
        if (this.outsideClickHandler) {
            document.removeEventListener('click', this.outsideClickHandler)
        }
        Array.from(this.submenuToggleBtns).forEach((btn) => {
            btn.parentNode.classList.remove(CLASSES.isOpen)
            btn.setAttribute('aria-expanded', 'false')
        })
    }
}

const navElement = document.querySelector(SELECTORS.nav)
if (navElement) {
    // eslint-disable-next-line no-unused-vars
    const nav = new Navigation(navElement)
    nav.init()
}

import 'intersection-observer'

const SELECTORS = {
    observedElement: '.js-observe'
}

const CLASSES = {
    isObserved: 'is-observed',
    inView: 'in-view'
}

function observe(elements) {
    const config = {
        rootMargin: '0px 0px -40% 0px'
    }
    const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio > 0) {
                window.setTimeout(() => {
                    entry.target.classList.add(CLASSES.inView)
                }, 1)
                io.unobserve(entry.target)
            }
        })
    }, config)

    elements.forEach((el) => {
        io.observe(el)
        el.classList.remove(SELECTORS.observedElement.replace('.', ''))
        el.classList.add(CLASSES.isObserved)
    })
}

const observedElements = document.querySelectorAll(SELECTORS.observedElement)
if (observedElements) {
    observe(observedElements)
}

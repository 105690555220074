// Vanilla JS Module Index

// EXTERNAL PACKAGES
// focus-visible polyfill, for proper focus styling
import 'focus-visible'

// STATIC MODULES
// common modules, included on every page
// using standard ES6 imports here because no codesplitting intended
import './modules/navigation'
import './modules/observer'
import './modules/cookienotice'

// DYNAMIC MODULES
// Index of {filename}: {js hook}
// JS Hooks have to be '.js-' prefixed classes/ids
const MODULES = {
    lazyload: '.js-lazyload',
    textfield: '.js-textfield',
    select: '.js-select',
    toast: '#js-toast'
}

const noop = () => {}
Object.keys(MODULES).forEach((name) => {
    if (document.querySelector(MODULES[name])) {
        import(`./modules/${name}`).then(noop)
    }
})
